@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&family=Poppins:wght@200;500&family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap);
.footer {
  /* position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; */
  color: #fff;
  padding: 10px 0;
}
.footer .footer-images {
  display: flex;
  justify-content: space-around;
}
.footer .footer-images .footer-block {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}
.footer .footer-images .footer-block .footer-text {
  margin-left: 0.5rem;
}
.footer .footer-images .footer-block i {
  color: #a3ff12;
}
.footer .footer-images .footer-block .footer-text h2 {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}
.footer .footer-images .footer-block .footer-text p {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}
.footer .rights {
  margin-top: 2em;
  text-align: center;
  color: #a3ff12;
}

@media only screen and (max-width: 768px) {
  .footer {
    position: relative;
  }
  .footer .footer-images {
    display: block;
  }
  .footer .footer-images .footer-block {
    margin: 1em 1em;
  }
}

.header {
  margin-top: 10px;
  margin-left: 10%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header img {
  width: 100px;
}
.header button {
  padding: 10px;
  background: #a3ff12 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #d6d6d6ad;
  border: 1px solid #707070;
  border-radius: 48px;
  width: 150px;
  height: 50px;
  padding: 5px 15px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    margin: 0;
    padding: 10px 20px;
  }
  .header img {
    width: 60px;
  }
  .header button {
    padding: 10px;
    width: 100px;
    height: 30px;
    padding: 5px 15px;
  }
}

.main-section {
  text-align: center;
  padding: 20px 0px;
}
.main-section .headings {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #ffffff;
 
  font-size: 1.5rem;
  margin-bottom: 0.5em;
  font-family: "Poppins", sans-serif;
}
.main-section .main-section-form {
  width: 50%;
  margin-left: 25%;
  text-align: left;
  background: transparent linear-gradient(180deg, #62ee12c4 0%, #06960d96 100%) 0%
    0% no-repeat padding-box;
  border-radius: 25px;
  padding: 25px;
  color: #ffffff;
}

.main-section .main-section-form .form-group {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-right: 15px;
  border: 1px solid #707070;
  border-radius: 6px;
}
.main-section .main-section-form .form-group input {
  width: 60%;
  border: none;
  outline: none;
}
.main-section .main-section-form .form-group div {
  display: flex;
  justify-content: end;
  align-items: center;
}
.main-section .main-section-form .form-group div img {
  width: 25px;
  height: 25px;
}
.main-section .main-section-form .form-group div p {
  color: #000000;
  margin-left: 10px;
}
.referal {
  width: 100%;
  outline: none;
  border: 1px solid #707070;
  border-radius: 6px;
  padding: 10px;
}

.mgtp {
  margin: 0.3em 0;
}

.link {
  margin: 1em 0;
}
.link a {
  color: #ffffff;
}
.buy {
  text-align: center;
  padding: 0.7em 2em;
  background-color: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
  margin: 0.7em 0;
  border-radius: 6px;
}

.smart {
  display: flex;
  justify-content: center;
}
.smart p {
  margin-left: 0.5em;
}

@media only screen and (max-width: 768px) {
  .main-section .main-section-form {
    width: 90%;
    margin-left: 5%;
    text-align: left;
  }
  .main-section .headings {
    font-size: 1rem;
    margin-bottom: 1em;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-image: url(/static/media/bg-texture-05.67b914c1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  line-height: 1;
}
.bold {
  font-weight: bolder;
}

